import { Table } from 'antd';
import { formatValue } from '@/Utils';

const SimpleComparisonTable = ({ data, title }) => {
    const columns = [
        {
            title: title,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Año pasado',
            dataIndex: 'last_year',
            key: 'last_year',
            align: 'right',
        },
        {
            title: 'Año actual',
            dataIndex: 'current_year',
            key: 'current_year',
            align: 'right',
        },
        {
            title: 'Desviación',
            dataIndex: 'versus',
            key: 'versus',
            align: 'right',
        },
    ];

    const allKeys = new Set([...Object.keys(data.last_year), ...Object.keys(data.current_year)]);

    // Calculate totals
    const totals = Array.from(allKeys).reduce((acc, key) => {
        acc.last_year += data.last_year[key] || 0;
        acc.current_year += data.current_year[key] || 0;
        return acc;
    }, { last_year: 0, current_year: 0 });

    // Calculate total deviation
    const totalDeviation = totals.current_year / totals.last_year - 1;

    const regularRows = Array.from(allKeys).map(key => ({
        key,
        name: key,
        last_year: formatValue(data.last_year[key] || 0),
        current_year: formatValue(data.current_year[key] || 0),
        versus: formatValue((data.versus[key] || 0) * 100, 2, 2) + '%',
    }));

    // Add totals row
    const tableData = [
        ...regularRows,
        {
            key: 'total',
            name: 'TOTAL',
            last_year: formatValue(totals.last_year),
            current_year: formatValue(totals.current_year),
            versus: formatValue(totalDeviation * 100, 2, 2) + '%',
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            onRow={(record) => ({
                style: record.key === 'total' ? {
                    backgroundColor: '#f5f5f5',
                    fontWeight: 'bold',
                    borderTop: '2px solid #d9d9d9'
                } : {}
            })}
        />
    );
};

export default SimpleComparisonTable;