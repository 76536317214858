import AuthContext from '@/context/AuthContext'
import axios from 'axios'
import { useContext, useEffect } from 'react'

const RequestInterceptor = ({ children }) => {
  const { logout, refreshAccessToken } = useContext(
    AuthContext
  )

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token')
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    return () => {
      axios.interceptors.request.eject(requestInterceptor)
    }
  }, [])

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        const originalRequest = error.config
        const errorCode = error.response?.data?.code
        const status = error.response?.status

        if (
          (status === 401 || status === 403) &&
          (errorCode === 'token_not_valid' || errorCode === 'token_expired') &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true
          try {
            const accessToken = await refreshAccessToken()
            if (accessToken && originalRequest.headers) {
              originalRequest.headers.Authorization = `Bearer ${accessToken}`
              return axios(originalRequest)
            }
          } catch (refreshError) {
            console.error('Error refreshing token:', refreshError)
          }
          logout()
          return Promise.reject(error)
        }
        return Promise.reject(error)
      }
    )

    return () => {
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [logout, refreshAccessToken])

  return <>{children}</>
}

export default RequestInterceptor
