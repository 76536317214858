// import { Outlet } from 'react-router-dom'
import { ShoppingOutlined, WalletOutlined, BuildOutlined, UploadOutlined, ShoppingCartOutlined } from '@ant-design/icons'

import MainPage from '@/components/MainPage'
import DocumentsChart from '@/components/DocumentsChart'
import YearComparisonTable from '@/components/YearComparisonTable'
import AdminPage from '@/components/AdminPage'
import StockPage from '@/components/StockPage'
import DailySales from '@/components/DailySales'
// import AccountTributarioClasification from '@/pages/AccountTributarioClasification'
// import Balance from '@/pages/Balance'
// import BalanceDetails from '@/pages/BalanceDetails'
// import Classified from '@/pages/Classified'
// import Companies from '@/pages/Companies'
// import ReportUserStats from '@/pages/ReportUserStats'
// import { Group } from '@/utils'

const privateRoutes = [
  {
    key: '1',
    path: '/company',
    label: 'Home',
    icon: <ShoppingOutlined />,
    element: <MainPage />
  },
  {
    key: '2',
    path: '/metricas',
    label: 'Metricas',
    icon: <WalletOutlined />,
    element: <DocumentsChart />
  },
  {
    key: '3',
    path: '/report',
    label: 'Reporte Mensual',
    icon: <BuildOutlined />,
    element: <YearComparisonTable />
  },
  {
    key: '4',
    path: '/daily-sales',
    label: 'Ventas Diarias',
    icon: <ShoppingCartOutlined />,
    element: <DailySales />
  },
  {
    key: '5',
    path: '/stocks',
    label: 'Stocks',
    icon: <BuildOutlined />,
    element: <StockPage />
  },
  {
    key: '6',
    path: '/admin',
    label: 'Admin',
    icon: <UploadOutlined />,
    element: <AdminPage />
  },
  // {
  //   path: '/statements',
  //   name: 'Balances',
  //   icon: <WalletOutlined />,
  //   element: <Outlet />,
  //   children: [
  //     {
  //       path: '/statements/list',
  //       name: 'Lista',
  //       icon: <UnorderedListOutlined />,
  //       element: <Balance />
  //     },
  //     {
  //       path: '/statements/:uuid',
  //       name: 'Detalle',
  //       icon: <WalletOutlined />,
  //       element: <BalanceDetails />,
  //       hiddenMenu: true
  //     },
  //     {
  //       path: '/statements/upload',
  //       name: 'Subir Balances',
  //       icon: <UploadOutlined />,
  //       element: <Outlet />,
  //       children: [
  //         {
  //           path: '/statements/upload/tributario',
  //           name: 'Tributario',
  //           icon: <UploadOutlined />,
  //           element: <AccountTributarioClasification />
  //         },
  //         {
  //           path: '/statements/upload/classified',
  //           name: 'Clasificados',
  //           icon: <UploadOutlined />,
  //           element: <Classified />
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: '/config',
  //   name: 'Configuración',
  //   icon: <BuildOutlined />,
  //   element: <Outlet />,
  //   groups: [Group.Jefatura],
  //   children: [
  //     {
  //       path: '/config/report-user-stats',
  //       name: 'Reporte de Usuarios',
  //       icon: <UnorderedListOutlined />,
  //       element: <ReportUserStats />,
  //       groups: [Group.Jefatura]
  //     }
  //   ]
  // }
]

export default privateRoutes
