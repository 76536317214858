import { Table } from 'antd';
import PropTypes from 'prop-types';
import { formatValue } from '@/Utils';

const columns = [
  {
    title: 'Oficina',
    dataIndex: 'office',
    key: 'office',
  },
  {
    title: 'Presupuesto Mes',
    className: 'budget',
    children: [
      { title: '%', dataIndex: 'estimated_sales_diff', key: 'estimated_sales_diff', className: 'budget', align: 'right' },
      { title: 'Mes', dataIndex: 'estimated_sales_total', key: 'estimated_sales_total', className: 'budget', align: 'right' },
      { title: 'A la fecha', dataIndex: 'estimated_sales_curr', key: 'estimated_sales_curr', className: 'budget', align: 'right' },
    ],
  },
  {
    title: 'Venta 2024 a la fecha',
    className: 'sales-2024',
    children: [
      { title: '% a la fecha', dataIndex: 'curr_year_to_date', key: 'curr_year_to_date', className: 'sales-2024', align: 'right' },
      { title: 'Neto', dataIndex: 'curr_year_neto', key: 'curr_year_neto', className: 'sales-2024', align: 'right' },
      { title: 'Cantidad', dataIndex: 'curr_year_quantity', key: 'curr_year_quantity', className: 'sales-2024', align: 'right' },
      { title: 'Costo', dataIndex: 'curr_year_cost', key: 'curr_year_cost', className: 'sales-2024', align: 'right' },
      { title: 'Margen', dataIndex: 'curr_year_margin', key: 'curr_year_margin', className: 'sales-2024', align: 'right' },
    ],
  },
  {
    title: 'Total 2023',
    dataIndex: 'total_2023',
    key: 'total_2023',
    className: 'sales-2023',
    align: 'right',
  },
  {
    title: 'Venta 2023 a la fecha (AA)',
    className: 'sales-2023',
    children: [
      { title: 'Neto', dataIndex: 'last_year_neto', key: 'last_year_neto', className: 'sales-2023', align: 'right' },
      { title: 'Cantidad', dataIndex: 'last_year_quantity', key: 'last_year_quantity', className: 'sales-2023', align: 'right' },
      { title: 'Costo', dataIndex: 'last_year_cost', key: 'last_year_cost', className: 'sales-2023', align: 'right' },
      { title: 'Margen', dataIndex: 'last_year_margin', key: 'last_year_margin', className: 'sales-2023', align: 'right' },
    ],
  },
  {
    title: 'Desviaciones',
    className: 'deviations',
    children: [
      { title: 'Neto Real/AA', dataIndex: 'neto_real_aa', key: 'neto_real_aa', className: 'deviations', align: 'right' },
      { title: 'Mg Real/AA', dataIndex: 'mg_real_aa', key: 'mg_real_aa', className: 'deviations', align: 'right' },
      { title: 'Neto Real/PP', dataIndex: 'neto_real_pp', key: 'neto_real_pp', className: 'deviations', align: 'right' },
    ],
  },
];


const ReportTable = ({ data }) => {
  // First calculate regular rows
  const regularRows = Object.keys(data)
    .sort((a, b) => a.localeCompare(b))
    .map((officeName, index) => ({
      key: index,
      office: officeName,
      estimated_sales_diff: data[officeName].estimated_sales['Difference'] ? formatValue(data[officeName].estimated_sales['Difference'] * 100) + '%' : '0%',
      estimated_sales_total: formatValue(data[officeName].estimated_sales['Total Month']),
      estimated_sales_curr: formatValue(data[officeName].estimated_sales['Until Current Day']),
      curr_year_to_date: data[officeName].curr_year['% to date'] ?
        formatValue(data[officeName].curr_year['% to date'] * 100) + '%' : '0%',
      curr_year_neto: formatValue(data[officeName].curr_year['Total Amount']),
      curr_year_quantity: formatValue(data[officeName].curr_year['Quantity']),
      curr_year_cost: formatValue(data[officeName].curr_year['Cost']),
      curr_year_margin: formatValue(data[officeName].curr_year['Margin'] * 100) + '%',
      total_2023: formatValue(data[officeName].last_year['Total of Year']),
      last_year_neto: formatValue(data[officeName].last_year['Total Amount']),
      last_year_quantity: formatValue(data[officeName].last_year['Quantity']),
      last_year_cost: formatValue(data[officeName].last_year['Cost']),
      last_year_margin: formatValue(data[officeName].last_year['Margin'] * 100) + '%',
      neto_real_aa: data[officeName].variation['Total Amount Net Real'] !== null ?
        formatValue(data[officeName].variation['Total Amount Net Real'] * 100, 2, 2) + '%' : '',
      mg_real_aa: data[officeName].variation['Mg Real/AA'] !== null ?
        formatValue(data[officeName].variation['Mg Real/AA'] * 100, 2, 2) + '%' : '',
      neto_real_pp: data[officeName].variation['Neto Real/PP'] !== null ?
        formatValue(data[officeName].variation['Neto Real/PP'] * 100, 2, 2) + '%' : '',
      raw_values: {
        estimated_sales_total: data[officeName].estimated_sales['Total Month'],
        estimated_sales_curr: data[officeName].estimated_sales['Until Current Day'],
        curr_year_neto: data[officeName].curr_year['Total Amount'],
        curr_year_quantity: data[officeName].curr_year['Quantity'],
        curr_year_cost: data[officeName].curr_year['Cost'],
        total_2023: data[officeName].last_year['Total of Year'],
        last_year_neto: data[officeName].last_year['Total Amount'],
        last_year_quantity: data[officeName].last_year['Quantity'],
        last_year_cost: data[officeName].last_year['Cost'],
      }
    }));

  // Calculate totals
  const totals = regularRows.reduce((acc, row) => {
    Object.keys(row.raw_values).forEach(key => {
      acc[key] = (acc[key] || 0) + (row.raw_values[key] || 0);
    });
    return acc;
  }, {});

  // Calculate percentages for totals
  const estimated_sales_diff = totals.estimated_sales_curr / totals.estimated_sales_total;
  const curr_year_to_date = totals.curr_year_neto / totals.estimated_sales_total;
  const curr_year_margin = (totals.curr_year_neto - totals.curr_year_cost) / totals.curr_year_neto;
  const last_year_margin = (totals.last_year_neto - totals.last_year_cost) / totals.last_year_neto;
  const neto_real_aa = (totals.curr_year_neto / totals.last_year_neto) - 1;
  const mg_real_aa = curr_year_margin - last_year_margin;
  const neto_real_pp = totals.curr_year_neto / totals.estimated_sales_curr - 1;

  // Create totals row
  const totalsRow = {
    key: 'zzz_total', // Using 'zzz_' prefix to ensure it sorts last
    office: 'Totales',
    estimated_sales_diff: formatValue(estimated_sales_diff * 100) + '%',
    estimated_sales_total: formatValue(totals.estimated_sales_total),
    estimated_sales_curr: formatValue(totals.estimated_sales_curr),
    curr_year_to_date: formatValue(curr_year_to_date * 100) + '%',
    curr_year_neto: formatValue(totals.curr_year_neto),
    curr_year_quantity: formatValue(totals.curr_year_quantity),
    curr_year_cost: formatValue(totals.curr_year_cost),
    curr_year_margin: formatValue(curr_year_margin * 100) + '%',
    total_2023: formatValue(totals.total_2023),
    last_year_neto: formatValue(totals.last_year_neto),
    last_year_quantity: formatValue(totals.last_year_quantity),
    last_year_cost: formatValue(totals.last_year_cost),
    last_year_margin: formatValue(last_year_margin * 100) + '%',
    neto_real_aa: formatValue(neto_real_aa * 100, 2, 2) + '%',
    mg_real_aa: formatValue(mg_real_aa * 100, 2, 2) + '%',
    neto_real_pp: formatValue(neto_real_pp * 100, 2, 2) + '%',
  };

  // Combine regular rows with totals row
  const tableData = [...regularRows, totalsRow];

  return <Table
    columns={columns}
    dataSource={tableData}
    pagination={false}
    summary={() => null} // Prevents double footer
    onRow={(record) => ({
      style: record.key === 'zzz_total' ? {
        fontWeight: 'bold',
      } : {}
    })}
  />;
};

ReportTable.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.shape({
      estimated_sales: PropTypes.shape({
        'Difference': PropTypes.number,
        'Total Month': PropTypes.number,
        'Until Current Day': PropTypes.number
      }),
      curr_year: PropTypes.shape({
        '% to date': PropTypes.number,
        'Total Amount': PropTypes.number,
        'Quantity': PropTypes.number,
        'Cost': PropTypes.number,
        'Margin': PropTypes.number,
        'Total of Year': PropTypes.number,
      }),
      last_year: PropTypes.shape({
        'Total Amount': PropTypes.number,
        'Quantity': PropTypes.number,
        'Cost': PropTypes.number,
        'Margin': PropTypes.number,
        'Total of Year': PropTypes.number,
      }),
      variation: PropTypes.shape({
        'Total Amount Net Real': PropTypes.number,
        'Mg Real/AA': PropTypes.number,
        'Neto Real/PP': PropTypes.number,
      }),
    })
  ).isRequired,
};

export default ReportTable;