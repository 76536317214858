import { useEffect, useState } from 'react';
import { Table, DatePicker, Select, Card, Space, Input, Button } from 'antd';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { fetchSalesDetail } from '@/requests/dataService';

const { RangePicker } = DatePicker;

const DailySales = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOffice, setSelectedOffice] = useState('Araucaria');
    const [dateRange, setDateRange] = useState([
        dayjs().startOf('month'),
        dayjs().subtract(1, 'day')
    ]);

    const offices = ['Araucaria', 'COSTANERA', 'Fabrica Web', 'MAYORISTA', 'MARKETPLACE'];

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        if (!dateRange[0] || !dateRange[1]) return;

        try {
            setLoading(true);
            const response = await fetchSalesDetail({
                start_date: dateRange[0].format('YYYY-MM-DD'),
                end_date: dateRange[1].format('YYYY-MM-DD'),
                office: selectedOffice
            });
            setData(response);
        } catch (error) {
            console.error('Error fetching sales detail:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters, confirm)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
    });

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'generation_date',
            key: 'generation_date',
            sorter: (a, b) => dayjs(a.generation_date).unix() - dayjs(b.generation_date).unix(),
            render: (text) => dayjs(text).format('DD-MM-YYYY'),
            width: 150,
            defaultSortOrder: 'descend',
        },
        {
            title: 'SKU',
            dataIndex: 'product_sku',
            key: 'product_sku',
            sorter: (a, b) => a.product_sku.localeCompare(b.product_sku),
            ...getColumnSearchProps('product_sku'),
        },
        {
            title: 'Documento',
            dataIndex: 'document_code',
            key: 'document_code',
            sorter: (a, b) => a.document_code.localeCompare(b.document_code),
            ...getColumnSearchProps('document_code'),
        },
        {
            title: 'Cliente',
            dataIndex: 'client_name',
            key: 'client_name',
            sorter: (a, b) => a.client_name.localeCompare(b.client_name),
            ...getColumnSearchProps('client_name'),
        },
        {
            title: 'Costo Neto',
            dataIndex: 'product_net_cost',
            key: 'product_net_cost',
            sorter: (a, b) => a.product_net_cost - b.product_net_cost,
            render: (value) => `$${value.toLocaleString('es-CL')}`,
        },
        {
            title: 'Venta Neta',
            dataIndex: 'total_amount',
            key: 'total_amount',
            sorter: (a, b) => a.total_amount - b.total_amount,
            render: (value) => `$${value.toLocaleString('es-CL')}`,
        },
        {
            title: 'Cantidad',
            dataIndex: 'quantity',
            key: 'quantity',
            sorter: (a, b) => a.quantity - b.quantity,
        },
        {
            title: 'Marketplace',
            dataIndex: 'marketplace_office',
            key: 'marketplace_office',
            sorter: (a, b) => (a.marketplace_office || '').localeCompare(b.marketplace_office || ''),
            ...getColumnSearchProps('marketplace_office'),
            render: (text) => text || '-',
            hidden: selectedOffice !== 'MARKETPLACE',
        },
    ].filter(column => !column.hidden);

    const handleRefresh = () => {
        fetchData();
    };

    const dataWithKeys = data.map((item, index) => ({
        ...item,
        uniqueKey: `${item.product_sku}-${item.client_name}-${index}`
    }));

    const calculateTotals = () => {
        return dataWithKeys.reduce((acc, item) => ({
            totalCost: acc.totalCost + (item.product_net_cost || 0),
            totalVenta: acc.totalVenta + (item.total_amount || 0),
            totalCantidad: acc.totalCantidad + (item.quantity || 0)
        }), {
            totalCost: 0,
            totalVenta: 0,
            totalCantidad: 0
        });
    };

    return (
        <div style={{ padding: '24px' }}>
            <Card title="Ventas Diarias" extra={
                <Button
                    icon={<ReloadOutlined />}
                    onClick={handleRefresh}
                    loading={loading}
                >
                    Refresh
                </Button>
            }>
                <Space style={{ marginBottom: 16 }}>
                    <RangePicker
                        value={dateRange}
                        onChange={setDateRange}
                        allowClear={false}
                        disabledDate={(current) => {
                            const tooFarInPast = current && current < dayjs('2023-01-01');
                            const isFuture = current && current >= dayjs().subtract(1, 'day');
                            return tooFarInPast || isFuture;
                        }}
                    />
                    <Select
                        value={selectedOffice}
                        onChange={setSelectedOffice}
                        style={{ width: 200 }}
                        options={offices.map(office => ({ value: office, label: office }))}
                    />
                </Space>

                <Table
                    columns={columns}
                    dataSource={dataWithKeys}
                    loading={loading}
                    rowKey="uniqueKey"
                    scroll={{ x: 'max-content' }}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total) => `Total ${total} items`,
                    }}
                    summary={() => {
                        const totals = calculateTotals();
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>Totales</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>
                                        ${totals.totalCost.toLocaleString('es-CL')}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>
                                        ${totals.totalVenta.toLocaleString('es-CL')}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>
                                        {totals.totalCantidad}
                                    </Table.Summary.Cell>
                                    {selectedOffice === 'MARKETPLACE' && (
                                        <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                    )}
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
            </Card>
        </div>
    );
};

export default DailySales;